
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,500;0,700;1,500&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;


*{
  margin:0;
  padding:0;
  font-family: 'Poppins', sans-serif;
  scroll-behavior: smooth;
}

body {
  font-size: 16px; /* Fuente base */
  position: relative;
}

h1 {
  font-size: 2.5em; /* Aproximadamente 40px */
}

h2 {
  font-size: 1.75em; /* Aproximadamente 28px */
}

h3 {
  font-size: 1.375em; /* Aproximadamente 22px */
}

p {
  font-size: 1em; /* 16px, igual que la fuente base */
}

label{
  font-size: 0.6em;
}

a {
  font-size: 1em; /* 16px, igual que la fuente base */
}

span {
  font-size: 0.8em; /* 80% del tamaño de la fuente del contenedor padre */
}

body {
  min-height: 100vh;
  overflow-y:hidden;
  overflow-x:hidden;
  height: 100vh;
}

.app {
  min-height: 100vh;
  position: relative;
}

a{
  text-decoration: none;
  color:#000000
}

#root{
  background-color: rgba(255, 255, 255, 0.3);
  min-height: 100vh;
}

.progressbar {
  margin-left:100px;
  position: relative;
  display: flex;
  justify-content: space-between;
  counter-reset: step;
  margin: 5rem 10rem 10rem;
  z-index:10;
}

.progressbar::before,
.progress {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  height: 10px;
  width: 100%;
  background-color: none;
  z-index: -1;
}

.progress {
  background-color: var(--primary-color);
  width: 0%;
  transition: 0.3s;
}

.progress-step {
  width: 6rem;
  height: 6rem;
  font-size: 4rem;
  font-weight: 600;
  background-color: #0249FD;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.progress-step::before {
  counter-increment: step;
  content: counter(step);
}

.progress-step::after {
  content: attr(data-title);
  position: absolute;
  top: calc(100% + 0.5rem);
  font-size: 2rem;
  color:#0249FD ;
}

.progress-step-active {
  background-color: var(--primary-color);
  color: red;
}

container{
  display:flex;
  flex-direction:column;
  justify-content:center;
  align-items:center;
  width:100%;
  height: 100vh;
  scroll-snap-type:y mandatory;
  overflow-y:scroll;

}


section{
  display:flex;
  flex-direction:row;
  flex-wrap:wrap;   
  scroll-snap-align:start;

}


.section1{
  background-color:red;
}

.section2{
  background-color: blue;
}

.google-login-button{
  background-color:red;
}



